import { Injectable } from "@angular/core";
import { Actions, createEffect } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { BehaviorSubject } from 'rxjs';
import { filter, take, tap } from 'rxjs/operators';
import { redoAction, undoAction } from '../actions/state.actions';
import { articlesReducer } from '../reducers/articles.reducer';
import { contactsReducer } from '../reducers/contacts.reducer';
import { notesReducer } from '../reducers/notes.reducer';
import { projectsReducer } from '../reducers/projects.reducer';
import { selectedProjectReducer } from '../reducers/selected-project.reducer';
import { tasksReducer } from '../reducers/tasks.reducer';
import { StoreNS } from '../types/store.interface';
import { PersistenceService } from './persistence.service';

const pastActions = JSON.parse(window.sessionStorage.getItem('task-helper.pastActions') || "[]");
const futureActions = JSON.parse(window.sessionStorage.getItem('task-helper.futureActions') || "[]");
const initalState = JSON.parse(window.sessionStorage.getItem('task-helper.initalState'));

@Injectable()
export class HistoryService {
    public pastActions = new BehaviorSubject(pastActions);
    public futureActions = new BehaviorSubject(futureActions);

    private initalState = initalState;
    private stateReducers = [
        { reducer: tasksReducer, property: "tasks" },
        { reducer: notesReducer, property: "notes" },
        { reducer: projectsReducer, property: "projects" },
        { reducer: contactsReducer, property: "contacts" },
        { reducer: articlesReducer, property: "articles" },
        { reducer: selectedProjectReducer, property: "selectedProject" },
    ];

    public saveState = createEffect(
    () =>
        this.actions$.pipe(
            filter(({ type }) => type !== "[task-helper] Undo state action"),
            filter(({ type }) => !type.startsWith("@ngrx")),
            tap((action) => {
                if (action.type !== "[task-helper] Redo state action") {
                    this.futureActions.next([]);
                    this.push(action);
                }
            })
        ),
        {
            dispatch: false,
        }
    );

    constructor(
        private actions$: Actions,
        private store: Store<StoreNS.Object>,
        private persistence: PersistenceService,
    ) {
        this.futureActions.subscribe((data) => this.persist("futureActions", data));
        this.pastActions.subscribe((data) => this.persist("pastActions", data));

        if (!initalState) {
            this.store.pipe(take(1)).subscribe((state) => {
                this.initalState = state;
                this.persist("initalState", state);
            })
        }
    }

    public back() {
        this.pop();
        const history = this.pastActions.getValue();

        const fragments = this.stateReducers.map(({ reducer, property }) => {
            const state = this.initalState[property];

            return {
                [property]: history.reduce(reducer, state),
            };
        });

        this.store.dispatch(
            undoAction(Object.assign({}, ...fragments)),
        );
    }

    public forward() {
        this.shift();
        const history = this.pastActions.getValue();

        const fragments = this.stateReducers.map(({ reducer, property }) => {
            const state = this.initalState[property];

            return {
                [property]: history.reduce(reducer, state),
            };
        });

        this.store.dispatch(
            redoAction(Object.assign({}, ...fragments)),
        );
    }

    private push(action) {
        const array = this.pastActions.getValue().slice(0);
        array.push(action);
        this.pastActions.next(array);
    }

    private pop() {
        const array = this.pastActions.getValue().slice(0);
        const removed = array.pop();
        this.unshift(removed);
        this.pastActions.next(array);

        return removed;
    }

    private shift() {
        const array = this.futureActions.getValue().slice(0);
        const removed = array.pop();
        this.push(removed);
        this.futureActions.next(array);

        return removed;
    }

    private unshift(action) {
        const array = this.futureActions.getValue().slice(0);
        array.push(action);
        this.futureActions.next(array);
    }

    private persist(
        dataType: "futureActions" | "pastActions" | "initalState",
        data: any = null
    ) {
        window.sessionStorage.setItem(`task-helper.${dataType}`, JSON.stringify(data));
    }

    public reset() {
        this.store.pipe(take(1)).subscribe((state) => {
            this.initalState = state;
            this.persist("initalState", state);
        })

        this.persist("futureActions", []);
        this.persist("pastActions", []);
    }
}