import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { switchMap } from 'rxjs/operators';
import { createTask, flagTask, moveTask, removeTask, toggleTaskVisibility, updateTask } from '../actions/task.actions';
import { contacts, ofStatus, tasks, tasksOfProject } from '../selectors';
import { StoreNS } from '../types/store.interface';
import { TaskNS } from '../types/task.interface';
import { ProjectService } from './project.service';

@Injectable()
export class TaskService {
    public readonly allTasks$ = this.store.pipe(select(tasks));

    public readonly todo$ = this.projectService.selectedProject$.pipe(
        switchMap((selectedProject) => {
            return this.store.pipe(
                select(tasksOfProject, { project: selectedProject }),
                select(ofStatus, { status: TaskNS.Status.Todo }),
            );
        }),
    );

    public readonly inProgress$ = this.projectService.selectedProject$.pipe(
        switchMap((selectedProject) => {
            return this.store.pipe(
                select(tasksOfProject, { project: selectedProject }),
                select(ofStatus, { status: TaskNS.Status["In progress"] }),
            );
        }),
    );

    public readonly review$ = this.projectService.selectedProject$.pipe(
        switchMap((selectedProject) => {
            return this.store.pipe(
                select(tasksOfProject, { project: selectedProject }),
                select(ofStatus, { status: TaskNS.Status.Review }),
            );
        }),
    );

    public readonly done$ = this.projectService.selectedProject$.pipe(
        switchMap((selectedProject) => {
            return this.store.pipe(
                select(tasksOfProject, { project: selectedProject }),
                select(ofStatus, { status: TaskNS.Status.Done }),
            );
        }),
    );

    public readonly backlog$ = this.projectService.selectedProject$.pipe(
        switchMap((selectedProject) => {
            return this.store.pipe(
                select(tasksOfProject, { project: selectedProject }),
                select(ofStatus, { status: TaskNS.Status.Backlog }),
            );
        }),
    );

    public readonly assignees$ = this.store.pipe(select(contacts));

    public readonly statuses = Object.keys(TaskNS.Status).filter(
        (status) => typeof TaskNS.Status[status] === 'number'
    );

    public readonly types = Object
        .keys(TaskNS.Type)
        .filter(key => typeof key !== 'number')
        .map(key => TaskNS.Type[key]);

    public readonly severities = Object
        .keys(TaskNS.Severity)
        .filter(key => typeof key !== 'number')
        .map(key => TaskNS.Severity[key]);

    public readonly complexies = Object
        .keys(TaskNS.Complexity)
        .filter(key => typeof key !== 'number')
        .map(key => TaskNS.Complexity[key]);

    constructor(
        private projectService: ProjectService,
        private store: Store<StoreNS.Object>
    ) {}

    public create(task: TaskNS.Object) {
        this.store.dispatch(createTask({ task }));
    }

    public remove(task: TaskNS.Object) {
        this.store.dispatch(removeTask({ task }));
    }

    public update(task: TaskNS.Object) {
        this.store.dispatch(updateTask({ task }));
    }

    public flag(taskId: TaskNS.ID) {
        this.store.dispatch(flagTask({ id: taskId }));
    }

    public toggleVisibility(taskId: TaskNS.ID) {
        this.store.dispatch(toggleTaskVisibility({ id: taskId }));
    }

    public move(task, bellowTask, aboveTask, toRowName) {
        const payload = moveTask({
          task,
          bellowTask,
          aboveTask,
          toRowName,
        });
        this.store.dispatch(payload);
      }
}