import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { v4 as uuid } from "uuid";
import { Store } from '@ngrx/store';
import { createProject, selectProject } from '../../state/actions/project.actions';
import { addContact, selectPrimaryUser } from '../../state/actions/contact.actions';
import { undoAction } from '../../state/actions/state.actions';
import { StoreNS } from '../../state/types/store.interface';
import { DEFAULT_STATE } from '../../state/default';
import { HistoryService } from '../../state/services/history.service';

@Component({
  selector: 'sh-onboarding',
  templateUrl: './onboarding.component.html',
  styleUrls: ['./onboarding.component.scss']
})
export class OnboardingComponent implements OnInit {
  isLinear = true;
  contact: FormGroup;
  project: FormGroup;

  constructor(
    private router: Router,
    private history: HistoryService,
    private store: Store<StoreNS.Object>,
    private _formBuilder: FormBuilder
  ) {}

  ngOnInit() {
    this.contact = this._formBuilder.group({
      name: ['', Validators.required],
      email: ['', Validators.required],
    });
    this.project = this._formBuilder.group({
      project: ['', Validators.required]
    });
  }

  public submit() {
    const state = JSON.parse(DEFAULT_STATE);
    this.store.dispatch(undoAction(state));

    const contact = {
      id: uuid(),
      ...this.contact.value,
      tasks: [],
    };

    this.store.dispatch(createProject(this.project.value));
    this.store.dispatch(addContact({ contact }));
    this.store.dispatch(selectPrimaryUser({ contact }));
    this.store.dispatch(selectProject(this.project.value));

    this.history.reset();
    this.router.navigateByUrl("/kanban");
  }
}
