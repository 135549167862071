import { Component, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";

export type DialogData = any;

@Component({
  selector: "dialog-confirm",
  styles: [],
  template: `
    <div style="display: flex; flex-direction: column;" class="container">
        <div style="flex: 1; display: flex; flex-direction: row; margin-top: 10px;" class="row">
            <mat-icon style="font-size: 64px; color: #ffd740;" inline="true">warning</mat-icon>
            <span style="margin-left: 10px; margin-top: 2px; font-size: 18px;">{{ data.title }}</span>
        </div>
        <div style="flex: 1; text-align: center; margin: 30px 0px; display: flex; flex-direction: row;" class="row">
            <span>{{ data.question }}</span>
        </div>
        <div style="flex: 1; display: flex; flex-direction: row-reverse;" class="row">
            <div>
                <button style="margin-left: 5px;" mat-dialog-close="cancel" mat-button>Cancel</button>
                <button style="margin-left: 5px;" mat-dialog-close="confirm" mat-flat-button color="warn">Confirm</button>
            </div>
        </div>
    </div>
  `,
})
export class ConfirmDialog {

  constructor(
    public dialogRef: MatDialogRef<ConfirmDialog>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {}

  public onNoClick(): void {
    this.dialogRef.close();
  }
}
