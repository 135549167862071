import { NgModule } from '@angular/core';
import { Routes, RouterModule, ɵEmptyOutletComponent, ExtraOptions } from '@angular/router';
import { FrameComponent } from './frame/frame.component';
import { OnboardingComponent } from './onboarding/onboarding.component';
import { ShellService } from './shell.service';

export const routes: Routes = [
  {
    path: "onboarding",
    component: OnboardingComponent,
  },
  {
    path: "",
    canActivate: [ShellService],
    component: FrameComponent,
    children: [
      {
        path: "kanban",
        data: {
          title: "Tasks",
          icon: { fontSet: "fa", fontIcon: "fa-clipboard-check" },
          position: "top"
        },
        loadChildren: () => import("./kanban/kanban.module")
          .then(m => m.KanbanModule),
      },
      {
        path: "team",
        data: {
          title: "Team",
          icon: { fontSet: "fa", fontIcon: "fa-users" },
          position: "top"
        },
        loadChildren: () => import("./contacts/contacts.module")
          .then(m => m.ContactsModule),
      },
      {
        path: "wiki",
        data: {
          title: "Docs",
          // disabled: true,
          icon: { fontSet: "fa", fontIcon: "fa-book" },
          position: "top"
        },
        loadChildren: () => import("./wiki/wiki.module")
          .then(m => m.WikiModule),
      },
      {
        path: "settings",
        data: {
          title: "Settings",
          icon: { fontSet: "fa", fontIcon: "fa-tools" },
          position: "bottom"
        },
        loadChildren: () => import("../settings/settings.module")
          .then(m => m.SettingsModule),
      },
      {
        path: "**",
        pathMatch: "full",
        redirectTo: "kanban"
      }
    ]
  },
  {
    path: "**",
    pathMatch: "full",
    redirectTo: "onboarding"
  }
];

export const routingConfiguration: ExtraOptions = {
  paramsInheritanceStrategy: 'always',
  useHash: true
}

@NgModule({
  providers: [ShellService],
  imports: [RouterModule.forRoot(routes, routingConfiguration)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
