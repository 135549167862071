import { createSelector } from '@ngrx/store';
import { first } from 'rxjs/operators';

export const projects = (state) => state.projects;
export const selectedProject = (state) => state.selectedProject;
export const tasks = (state) => state.tasks;
export const notes = (state) => state.notes;
export const contacts = (state) => state.contacts;
export const articles = (state) => state.articles;

export const ofProject = (state, { project }) => state.filter(entity => entity.project === project);
export const ofStatus = (state, { status }) => state.filter(entity => entity.status === status);
export const ofComplexity = (state, { complexity }) => state.filter(entity => entity.complexity === complexity);
export const ofTask = (state, { taskId }) => state.filter(entity => entity.taskId === taskId);
export const ofAssignee = (state, { assigneeId }) => state.filter(entity => entity.assignee === assigneeId);
export const ofPrimaryUser = (state) => [...state || []].find(entity => entity.primaryUser);
export const ofId = (state, { id }) => state.find(entity => entity.id === id);

export const notesOfProject = createSelector(notes, ofProject);
export const notesOfTask = createSelector(notes, ofTask);

export const tasksOfProject = createSelector(tasks, ofProject);
export const tasksOfStatus = createSelector(tasks, ofStatus);
export const tasksOfComplexity = createSelector(tasks, ofComplexity);
export const tasksOfAssignee = createSelector(tasks, ofAssignee);

export const primaryUser = createSelector(contacts, ofPrimaryUser);

export const contact = createSelector(contacts, ofId);
export const task = createSelector(tasks, ofId);