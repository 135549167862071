import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { createArticle, updateArticle } from '../actions/article.actions';
import { ArticleNS } from '../types/article.interface';
import { StoreNS } from '../types/store.interface';

@Injectable()
export class ArticleService {

    constructor(
        private store: Store<StoreNS.Object>,
    ) {}

    public update(article: ArticleNS.Object) {
        this.store.dispatch(updateArticle({ article }));
    }

    public create(article: ArticleNS.Object) {
        this.store.dispatch(createArticle({ article }));
    }
}