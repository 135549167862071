import { createAction, props } from "@ngrx/store";
import { TaskNS } from "../types/task.interface";

// Task Actions
export const createTask = createAction("[task-helper] Create task", props<{ task: TaskNS.Object }>());
export const updateTask = createAction("[task-helper] Update task", props<{ task: TaskNS.Object }>());
export const removeTask = createAction("[task-helper] Remove task", props<{ task: TaskNS.Object }>());
export const moveTask = createAction("[task-helper] Move task", props<{
    task: TaskNS.Object,
    bellowTask: TaskNS.Object,
    aboveTask: TaskNS.Object,
    toRowName: TaskNS.Status,
}>());

// Task Toggle Actions
export const flagTask = createAction("[task-helper] Flag task", props<{ id: TaskNS.ID }>());
export const toggleTaskVisibility = createAction("[task-helper] Toggle task visibility", props<{ id: TaskNS.ID }>());