import { Injectable } from "@angular/core";
import { v4 as uuid } from 'uuid';
import { select, Store } from '@ngrx/store';
import { shareReplay } from 'rxjs/operators';
import { addContact, removeContact, updateContact } from '../actions/contact.actions';
import { contact, contacts, primaryUser } from '../selectors';
import { ContactNS } from '../types/contact.interface';
import { StoreNS } from '../types/store.interface';

@Injectable()
export class ContactService {
    public readonly contacts$ = this.store.pipe(select(contacts), shareReplay(1));
    public readonly primaryUser$ = this.store.pipe(select(primaryUser));

    constructor(
        private store: Store<StoreNS.Object>,
    ) {}

    public getContact(id: string) {
        return this.contacts$.pipe(select(contact, { id }));
    }

    public create(contact: ContactNS.CreateObject) {
        this.store.dispatch(addContact({ contact: {
            tasks: [],
            ...contact,
            id: uuid(),
        } }));
    }

    public update(contact: ContactNS.Object) {
        this.store.dispatch(updateContact({ contact }));
    }

    public remove(contact: ContactNS.Object) {
        this.store.dispatch(removeContact({ contact }));
    }
}