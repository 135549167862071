import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { DEFAULT_STATE } from './default';
import { articlesReducer } from './reducers/articles.reducer';
import { contactsReducer } from './reducers/contacts.reducer';

import { notesReducer } from './reducers/notes.reducer';
import { projectsReducer } from './reducers/projects.reducer';
import { selectedProjectReducer } from './reducers/selected-project.reducer';
import { tasksReducer } from './reducers/tasks.reducer';
import { ArticleService } from './services/article.service';
import { ContactService } from './services/contact.service';

import { HistoryService } from './services/history.service';
import { MigrationService } from './services/migration.service';
import { NoteService } from './services/note.service';
import { PersistenceService } from './services/persistence.service';
import { ProjectService } from './services/project.service';
import { TaskService } from './services/task.service';

@NgModule({
  declarations: [],
  imports: [
    StoreModule.forRoot({
      selectedProject: selectedProjectReducer,
      projects: projectsReducer,
      tasks: tasksReducer,
      notes: notesReducer,
      contacts: contactsReducer,
      articles: articlesReducer,
    }),
    EffectsModule.forRoot([
      ProjectService,
      TaskService,
      NoteService,
      HistoryService,
      MigrationService,
    ]),
  ],
  exports: [StoreModule],
  providers: [
    ProjectService,
    TaskService,
    HistoryService,
    NoteService,
    MigrationService,
    ContactService,
    PersistenceService,
    ArticleService,
  ],
})
export class StateModule {
  static getInitalState(property?) {
    const state = JSON.parse(
      window.localStorage.getItem('task-helper.state') || DEFAULT_STATE
    );

    if (property) {
      return state[property];
    }

    return state;
  }
}
