import { Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { AboutDialog } from './about.dialog';
import { ConfirmDialog } from './confirm.dialog';
import { RenameDialog } from './rename.dialog';

@Injectable()
export class DialogSerivce {
    public dialogs: { name: string, component: any, config: MatDialogConfig }[] = [
        { name: "confirmDelete", component: ConfirmDialog, config: { width: "375px", autoFocus: false } },
        { name: "aboutTaskHelper", component: AboutDialog, config: { width: "375px", autoFocus: false, data: { title: "About Task Helper" } } },
        { name: "renameProject", component: RenameDialog, config: { width: "375px" }}
    ];

    constructor(
        public dialog: MatDialog,
    ) {}

    public open(name: string, data: any) {
        const dialog = this.dialogs.find(dialog => dialog.name === name);

        return this.dialog.open(dialog.component, { ...dialog.config, data: data || dialog.config.data });
    }
}