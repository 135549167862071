import { Component, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { version, name } from '../../../../../package.json';

export type DialogData = any;

@Component({
  selector: "dialog-about",
  styles: [],
  template: `
    <div style="display: flex; flex-direction: column;" class="container">
        <div style="flex: 1; display: flex; flex-direction: row;" class="row">
            <span style="margin-top: 2px; font-size: 18px;">About {{ nameToTitle(pkgJSON.name) }}</span>
        </div>
        <div style="flex: 1; text-align: center; margin-top: 30px; display: flex; flex-direction: row;" class="row">
          <span>Version {{ pkgJSON.version }} (Offical build)</span>
        </div>
        <div style="flex: 1; display: flex; flex-direction: row; margin-top: 10px;" class="row">
          <span>Copyright 2020 Brandon Bleau. All rights reserved.</span>
        </div>
    </div>
  `,
})
export class AboutDialog {
  public readonly pkgJSON = { version, name };

  constructor(
    public dialogRef: MatDialogRef<AboutDialog>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {}

  public onNoClick(): void {
    this.dialogRef.close();
  }

  public nameToTitle(name: string): string {
    return name
      .split('-')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  }
}
