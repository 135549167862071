import { createAction, props } from "@ngrx/store";
import { ContactNS } from '../types/contact.interface';

// Event Actions
export const addContact = createAction("[task-helper] Add contact", props<{ contact: ContactNS.Object }>());
export const removeContact = createAction("[task-helper] Remove contact", props<{ contact: ContactNS.Object }>());
export const updateContact = createAction("[task-helper] Update contact", props<{ contact: ContactNS.Object }>());

export const selectPrimaryUser = createAction("[task-helper] Set primary user", props<{ contact: ContactNS.Object }>());

/* Invite a contact to a calendar event */
export const inviteContact = createAction("[task-helper] Invite contact", props<{ contact: ContactNS.Object }>());

/* Assign a task to a contact */
export const assignToContact = createAction("[task-helper] Assign task", props<{ contact: ContactNS.Object }>());
export const unassignToContact = createAction("[task-helper] Unassign task", props<{ contact: ContactNS.Object }>());