<mat-card class="onboarding">
  <h1>Onboarding</h1>
  <!-- <p>Greetings and salutations! Before we can jump into the application I would like</p> -->
  <mat-vertical-stepper [linear]="isLinear" #stepper>
    <!-- <mat-step>
      <ng-template matStepLabel>Welcome message</ng-template>
      <p>Welcome! Task helper is designed to provide you with a friendly and intuitive </p>
      <div style="margin-top: 30px;">
        <button mat-flat-button color="primary" matStepperNext>Get started</button>
      </div>
    </mat-step> -->
    <mat-step [stepControl]="contact">
      <form [formGroup]="contact">
        <ng-template matStepLabel>User profile</ng-template>
        <p>
          Before we can get started using the application we need to know a
          little more about you as the main user. The information you provide
          will be used to construct your inital profile, but dont worry this
          information can be changed from settings at any time.
        </p>
        <div class="onboarding__input">
          <mat-form-field>
            <mat-label>Full name</mat-label>
            <input
              matInput
              formControlName="name"
              placeholder="John Doe"
              required
            />
          </mat-form-field>
          <mat-form-field>
            <mat-label>Email address</mat-label>
            <input
              matInput
              type="email"
              formControlName="email"
              placeholder="user@domain.tld"
              required
            />
          </mat-form-field>
        </div>
        <div>
          <button mat-button matStepperNext>Continue</button>
        </div>
      </form>
    </mat-step>
    <mat-step [stepControl]="project">
      <form [formGroup]="project">
        <ng-template matStepLabel>Project details</ng-template>
        <p>This information is used to construct your first project within the app, so that it is available upon loading in.</p>
        <div class="input">
          <mat-form-field>
            <mat-label>Project name</mat-label>
            <input
              matInput
              formControlName="project"
              placeholder="Workspace"
              required
            />
          </mat-form-field>
        </div>
        <div>
          <button mat-button matStepperPrevious>Back</button>
          <button mat-button matStepperNext>Continue</button>
        </div>
      </form>
    </mat-step>
    <mat-step>
      <ng-template matStepLabel>Done</ng-template>
      <p>Congradulations {{ contact?.value?.name }}! You are ready to start working on {{ project?.value?.project }} using the application. If you run into any problems there is a help section in the more actions menu.</p>
      <div>
        <button mat-button matStepperPrevious>Back</button>
        <button mat-button (click)="submit()">Get started</button>
      </div>
    </mat-step>
  </mat-vertical-stepper>
</mat-card>
