
    <div style="display: flex; flex-direction: column;" class="container">
        <div style="flex: 1; display: flex; flex-direction: row;" class="row">
            <span style="font-size: 18px;">Rename {{ data.type }}</span>
        </div>
        <div style="flex: 1; text-align: center; margin-top: 30px; display: flex; flex-direction: row;" class="row">
            <span>{{ data.question }}</span>
        </div>
        <div style="flex: 1; text-align: center; margin-top: 15px; display: flex; flex-direction: row;" class="row">
            <form ngForm #form style="flex: 1; display: flex;">
                <mat-form-field style="flex: 1;">
                    <mat-label>{{ data.type }}</mat-label>
                    <input matInput name="renameTo" [placeholder]="data.initalValue" [value]="data.initalValue">
                </mat-form-field>
            </form>
        </div>
        <div style="flex: 1; display: flex; margin-top: 30px; flex-direction: row-reverse;" class="row">
            <div>
                <button style="margin-left: 5px;" mat-dialog-close="cancel" mat-button>Cancel</button>
                <button style="margin-left: 5px;" (click)="onSubmit(form)" mat-dialog-close="confirm" mat-flat-button color="primary">Rename</button>
            </div>
        </div>
    </div>
  