import { moveItemInArray } from '@angular/cdk/drag-drop';
import { createReducer, on } from '@ngrx/store';
import produce from 'immer';
import { TaskNS } from '../types/task.interface';
import { createTask, flagTask, moveTask, removeTask, toggleTaskVisibility, updateTask } from '../actions/task.actions';
import { StateModule } from '../state.module';
import { removeProject, renameProject } from '../actions/project.actions';
import { redoAction, undoAction } from '../actions/state.actions';

export const initalState: TaskNS.Object[] = StateModule.getInitalState("tasks");

const _tasksReducer = createReducer(
    initalState,
    on(redoAction, produce((tasks, { tasks: nextTasks }) => {
        return nextTasks ? nextTasks : tasks;
    })),
    on(undoAction, produce((tasks, { tasks: nextTasks }) => {
        return nextTasks ? nextTasks : tasks;
    })),
    on(createTask, produce((tasks, { task }) => {
        return tasks.concat([task]);
    })),
    on(removeTask, produce((tasks, { task: { id } }) => {
        return tasks.filter(task => task.id !== id);
    })),
    on(updateTask, produce((tasks, { task }) => {
        const taskIndex = tasks.findIndex(_task => _task.id === task.id);
        Object.assign(tasks[taskIndex], task);
    })),
    on(flagTask, produce((tasks, { id }) => {
        const taskIndex = tasks.findIndex(task => task.id === id);
        tasks[taskIndex].flagged = !tasks[taskIndex].flagged;
    })),
    on(toggleTaskVisibility, produce((tasks, { id }) => {
        const taskIndex = tasks.findIndex(task => task.id === id);
        tasks[taskIndex].hidden = !tasks[taskIndex].hidden;
    })),
    on(renameProject, produce((tasks, { to: toProject, from: fromProject }) => {
        tasks.forEach(task => {
            if (task.project === fromProject) {
                task.project = toProject;
            }
        });
    })),
    on(removeProject, produce((tasks, { project }) => {
        return tasks.filter(task => task.project !== project);
    })),
    on(moveTask, produce((state, { task, aboveTask, bellowTask, toRowName }) => {
        const index = state.findIndex(_task => _task.id === task.id);

        if (index !== -1) {
            Object.assign(state[index], { status: TaskNS.Status[toRowName] });
        }

        if (bellowTask && !aboveTask) {
            const bellowIndex = state.findIndex(_task => _task.id === bellowTask.id);
            moveItemInArray(state, index, bellowIndex);
        } else if (bellowTask && aboveTask) {
            const bellowIndex = state.findIndex(_task => _task.id === bellowTask.id);
            moveItemInArray(state, index, bellowIndex);
        } else if (!bellowTask && aboveTask) {
            const aboveIndex = state.findIndex(_task => _task.id === aboveTask.id);
            moveItemInArray(state, index, aboveIndex + 1);
        }
    })),
);

export function tasksReducer(state, action) {
    return _tasksReducer(state, action);
}