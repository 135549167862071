import { Injectable } from "@angular/core";
import { Store } from '@ngrx/store';

@Injectable()
export class PersistenceService {
    private initalState;

    constructor(
        private store: Store<any>,
    ) {
        this.store.subscribe(state => {
            if (!this.initalState) {
                this.initalState = state;
            }

            localStorage.setItem('task-helper.state', JSON.stringify(state));
        });
    }
}