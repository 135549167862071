import { ContactNS } from './contact.interface';

export namespace TaskNS {
    export type ID = string;

    export enum Type {
        "Bug",
        "Story",
        "Feature",
        "Spike",
        "Refactor",
        "Task",
    }

    export enum Status {
        "Todo",
        "In progress",
        "Review",
        "Done",
        "Backlog",
    }

    export enum Complexity {
        "1 point",
        "2 points",
        "3 points",
        "5 points",
        "8 points",
        "13 points",
    }

    export enum Severity {
        "Lowest",
        "Low",
        "Medium",
        "High",
        "Critical"
    }

    export interface Object {
        id: ID;
        title: string;
        description: string;
        project: string;
        status: Status;

        type?: Type;
        complexity?: Complexity;
        severity?: Severity;

        assignee?: ContactNS.ID;

        start?: Date;
        end?: Date;

        created?: Date;
        updated?: Date;

        flagged?: boolean;
        hidden?: boolean;
    }
}