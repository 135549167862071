import { Injectable } from '@angular/core';
import { v4 as uuid } from 'uuid';
import { ActivatedRoute } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { from } from 'rxjs';
import { filter, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { addNote, removeNote } from '../actions/note.actions';
import { removeProject } from '../actions/project.actions';
import { notesOfTask } from '../selectors';
import { NoteNS } from '../types/note.interface';
import { StoreNS } from '../types/store.interface';

@Injectable()
export class NoteService {
    public notes$ = this.activatedRoute && this.activatedRoute.queryParams.pipe(
        filter(({ taskId }) => !!taskId),
        switchMap(({ taskId }) => {
            return this.store.pipe(select(notesOfTask, { taskId }));
        }),
    );

    public _onProjectRemoved$$ = createEffect(() => this.actions$.pipe(
        ofType(removeProject),
        withLatestFrom(this.store),
        switchMap(([action, state]) => {
            return from(state
                .notes
                .filter(note => note.project === action.project)
                .map(note => removeNote(note)));
        })
    ));

    constructor(
        private actions$: Actions,
        private activatedRoute: ActivatedRoute,
        private store: Store<StoreNS.Object>,
    ) {}

    public add(note: NoteNS.CreateObject) {
        this.store.dispatch(addNote({
            id: uuid(),
            created: new Date().toISOString(),
            ...note,
        }));
    }

    public remove(note: NoteNS.Object) {
        this.store.dispatch(removeNote(note));
    }
}