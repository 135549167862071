import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import {MatToolbarModule} from '@angular/material/toolbar';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatMenuModule} from '@angular/material/menu';
import {MatDividerModule} from '@angular/material/divider';
import {MatListModule} from '@angular/material/list';
import { MatTooltipModule } from '@angular/material/tooltip';
import { AboutDialog } from './dialogs/about.dialog';
import { ConfirmDialog } from './dialogs/confirm.dialog';
import { DialogSerivce } from './dialogs/dialog.service';
import { MatDialogModule } from '@angular/material/dialog';
import { RenameDialog } from './dialogs/rename.dialog';
import { MatInputModule } from '@angular/material/input';
import { StateModule } from '../state/state.module';
import { FrameModule } from './frame/frame.module';
import { OnboardingModule } from './onboarding/onboarding.module';

@NgModule({
  declarations: [
    AppComponent,
    ConfirmDialog,
    AboutDialog,
    RenameDialog,
  ],
  entryComponents: [ConfirmDialog, AboutDialog, RenameDialog],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatToolbarModule,
    MatButtonModule,
    MatIconModule,
    MatSidenavModule,
    MatMenuModule,
    MatDividerModule,
    MatListModule,
    MatTooltipModule,
    MatDialogModule,
    MatInputModule,
    StateModule,
    FrameModule,
    OnboardingModule,
  ],
  providers: [
    DialogSerivce,
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
