import { Injectable } from "@angular/core";
import { CanActivate, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { map } from 'rxjs/operators';
import { StoreNS } from '../state/types/store.interface';

@Injectable()
export class ShellService implements CanActivate {
    constructor(private store: Store<StoreNS.Object>, private router: Router) {}

    public canActivate() {
        return this.store.pipe(map(store => {
            if (store.projects.length === 0) {
                return this.router.parseUrl('onboarding');
            }

            return true;
        }));
    }
}