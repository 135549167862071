export const DEFAULT_STATE = JSON.stringify({
    selectedProject: null,
    projects: [],
    tasks: [],
    notes: [],
    contacts: [],
    articles: [
        {
            title: "Home page",
            slug: "wiki",
            contents: "<3",
            authors: [],
            readonly: true,
            created: new Date().toISOString(),
            updated: new Date().toISOString()
        },
    ],
});