import { createReducer, on } from "@ngrx/store";
import produce from 'immer';
import { createArticle, updateArticle } from '../actions/article.actions';
import { undoAction } from '../actions/state.actions';
import { StateModule } from '../state.module';
import { ArticleNS } from '../types/article.interface';

export const initalState: ArticleNS.Object[] = StateModule.getInitalState("articles");

console.log(initalState);

const _articlesReducer = createReducer(
    initalState,
    on(createArticle, produce((articles, { article }) => {
        return [...articles, article];
    })),
    on(updateArticle, produce((articles, { article: updatedArticle }) => {
        articles.forEach(article => {
            if (article.title === updatedArticle.title) {
                Object.assign(article, updatedArticle);
            }
        });
    })),
    on(undoAction, produce((articles, { articles: nextArticles }) => nextArticles)),
);

export function articlesReducer(state, action) {
    return _articlesReducer(state, action);
}