import { createReducer, on } from '@ngrx/store';
import { TaskNS } from '../types/task.interface';
import { StateModule } from '../state.module';
// import { redoAction, undoAction } from '../actions/state.actions';
import { addContact, removeContact, selectPrimaryUser, updateContact } from '../actions/contact.actions';
import produce from 'immer';
import { undoAction } from '../actions/state.actions';
import { createTask, removeTask, updateTask } from '../actions/task.actions';

export const initalState: TaskNS.Object[] = StateModule.getInitalState("contacts");

const _contactsReducer = createReducer(
    initalState,
    on(addContact, produce((contacts, { contact }) => {
        return [...contacts, contact];
    })),
    on(selectPrimaryUser, produce((contacts, { contact: { id: primaryUserId } }) => {
        contacts.forEach(contact => {
            if (contact.id === primaryUserId) {
                contact.primaryUser = true;
            } else {
                contact.primaryUser = undefined;
            }
        });
    })),
    on(updateTask, produce((contacts, { task: newTask }) => {
        const contactId = newTask.assignee;

        contacts.forEach(contact => {
            if (contact.id === contactId) {
                contact.tasks.push(newTask.id);
            } else {
                contact.tasks = contact.tasks.filter(taskId => taskId !== newTask.id);
            }
        });
    })),
    on(createTask, produce((contacts, { task: newTask }) => {
        const contactId = newTask.assignee;

        contacts.forEach(contact => {
            if (contact.id === contactId) {
                contact.tasks.push(newTask.id);
            } else {
                contact.tasks = contact.tasks.filter(taskId => taskId !== newTask.id);
            }
        });
    })),
    on(removeTask, produce((contacts, { task: deletedTask }) => {
        contacts.forEach(contact => {
            contact.tasks = contact.tasks.filter(taskId => taskId !== deletedTask.id);
        });
    })),
    on(removeContact, produce((contacts, { contact: { id } }) => {
        return contacts.filter(contact => contact.id !== id);
    })),
    on(updateContact, produce((contacts, { contact: updatedContact }) => {
        const contactIndex = contacts.findIndex(contact => contact.id === updatedContact.id);
        Object.assign(contacts[contactIndex], updatedContact);
    })),
    on(undoAction, produce((contacts, { contacts: nextContacts }) => {
        if (nextContacts) return nextContacts;
    })),
);

export function contactsReducer(state, action) {
    return _contactsReducer(state, action);
}