import { createReducer, on } from "@ngrx/store";
import produce from 'immer';
import { renameProject, removeProject, createProject } from '../actions/project.actions';
import { redoAction, undoAction } from '../actions/state.actions';
import { createTask, updateTask } from '../actions/task.actions';
import { StateModule } from '../state.module';
import { ProjectNS } from '../types/project.interface';

export const initalState: ProjectNS.Value[] = StateModule.getInitalState("projects");

const replacedState = (projects, { projects: nextProjects }) =>
    nextProjects
    ? nextProjects
    : projects;

const _projectsReducer = createReducer(
    initalState,
    on(undoAction, produce(replacedState)),
    on(redoAction, produce(replacedState)),
    on(createProject, produce((projects, { project }) => {
        projects.push(project);
    })),
    on(createTask, produce((projects, { task: { project } }) => {
        return Array.from(new Set(projects.concat([project])));
    })),
    on(updateTask, produce((projects, { task: { project } }) =>
    project
    ? Array.from(new Set(projects.concat([project])))
    : projects)),
    on(renameProject, produce((projects, { from: currentProject, to: project }) => {
        const currentIndex = projects.findIndex(project => project === currentProject);
        projects.splice(currentIndex, 1, project);
    })),
    on(removeProject, produce((projects, { project }) => {
        return projects.filter(_project => _project !== project);
    })),
);

export function projectsReducer(state, action) {
    return _projectsReducer(state, action);
}