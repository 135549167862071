<mat-toolbar color="primary">
    <mat-toolbar-row class="topbar">
      <button mat-button (click)="showMenu = !showMenu;" class="actionIcon" aria-label="Example icon-button with menu icon">
        <mat-icon fontSet="fa" inline="true" fontIcon="fa-bars"></mat-icon>
      </button>
      <button
        *ngIf="context?.returnTo"
        [routerLink]="context.returnTo"
        style="margin-right: 5px;"
        matTooltip="Go back"
        mat-icon-button
      >
        <mat-icon>arrow_back</mat-icon>
      </button>
      <button
        *ngIf="context?.goBack"
        (click)="goBack()"
        style="margin-right: 5px;"
        matTooltip="Go back"
        mat-icon-button
      >
        <mat-icon>arrow_back</mat-icon>
      </button>
      <span *ngIf="context">{{ context.title }}</span>
      <span class="spacer"></span>
      <router-outlet name="topbarActions"></router-outlet>
    </mat-toolbar-row>
  </mat-toolbar>
  
  <mat-sidenav-container class="example-container">
    <mat-sidenav mode="side" [opened]="showMenu">
      <div class="container">
        <ng-container *ngFor="let route of routes$ | async">
          <button
            *ngIf="route.position !== 'bottom'"
            mat-button
            [disabled]="route.disabled"
            class="actionIcon"
            [routerLink]="'/' + route.path"
            routerLinkActive="active"
            [matTooltip]="route.title"
            aria-label="Example icon-button with menu icon"
          >
            <mat-icon
              *ngIf="route.icon as icon; else displayTitle"
              [fontSet]="icon.fontSet"
              inline="true"
              [fontIcon]="icon.fontIcon"
            ></mat-icon>
            <ng-template #displayTitle>{{ route.title }}</ng-template>
          </button>
        </ng-container>
        <div class="spacer"></div>
        <ng-container *ngFor="let route of routes$ | async">
          <button
            *ngIf="route.position === 'bottom'"
            mat-button
            [disabled]="route.disabled"
            class="actionIcon"
            [routerLink]="'/' + route.path"
            routerLinkActive="active"
            [matTooltip]="route.title"
            aria-label="Example icon-button with menu icon"
          >
            <mat-icon
              *ngIf="route.icon as icon; else displayTitle"
              [fontSet]="icon.fontSet"
              inline="true"
              [fontIcon]="icon.fontIcon"
            ></mat-icon>
            <ng-template #displayTitle>{{ route.title }}</ng-template>
          </button>
        </ng-container>
      </div>
    </mat-sidenav>
    <mat-sidenav-content>
      <router-outlet
        (activate)="activateRoute($event)"
        (deactivate)="deactivateRoute($event)"  
      ></router-outlet>
    </mat-sidenav-content>
  </mat-sidenav-container>