import { createReducer, on } from '@ngrx/store';
import produce from 'immer';
import { renameProject, selectProject } from '../actions/project.actions';
import { redoAction, undoAction } from '../actions/state.actions';
import { StateModule } from '../state.module';
import { ProjectNS } from '../types/project.interface';

export const initalState: ProjectNS.Selected[] = StateModule.getInitalState("selectedProject");

const _selectedProjectReducer = createReducer(
    initalState,
    on(redoAction, produce((selectedProject, { selectedProject: project }) => {
        return project ? project : selectedProject;
    })),
    on(undoAction, produce((selectedProject, { selectedProject: project }) => {
        return project ? project : selectedProject;
    })),
    on(selectProject, produce((selectedProject, { project }) => project)),
    on(renameProject, produce((selectedProject, { to, from }) => selectedProject === from ? to : selectedProject)),
);

export function selectedProjectReducer(state, action) {
    return _selectedProjectReducer(state, action);
}