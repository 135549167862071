import { createReducer, on } from '@ngrx/store';
import { v4 as uuid } from "uuid";
import produce from 'immer';
import { NoteNS } from '../types/note.interface';
import { addNote, removeNote } from '../actions/note.actions';
import { removeProject, renameProject } from '../actions/project.actions';
import { redoAction, undoAction } from '../actions/state.actions';
import { createTask, flagTask, moveTask, removeTask, toggleTaskVisibility, updateTask } from '../actions/task.actions';
import { StateModule } from '../state.module';

export const initalState: NoteNS.Object[] = StateModule.getInitalState("notes");

const replacedState = (pastNotes, { notes }) =>
    notes
    ? notes
    : pastNotes;

const notesWithAddition = (notes, note) =>
    note
    ? notes.concat([note])
    : notes;

const notesWithRedaction = (notes, note) => {
    const noteIndex = notes.findIndex(_note => _note === note);
    notes.splice(noteIndex, 1);
};

const noteAboutTaskAction = (notes, { type, task }): NoteNS.Object[] => notes.concat([{
    id: uuid(),
    message: `${type} user action taken`,
    project: task.project,
    taskId: task.id,
    created: new Date().toISOString(),
}]);

const noteAboutProjectAction = (notes, { type, to: project, from: currentProject  }) => {
    const taskIds = Array.from(new Set(
        notes
            .filter(note => note.project === currentProject)
            .map(note => note.taskId)
    ));

    return JSON.parse(JSON.stringify(notes))
        .map(note => {
            if (note.project === currentProject) {
                note.project = project;
            }

            return note;
        })
        .concat(taskIds.map(taskId => ({
            id: uuid(),
            message: `${type} from ${currentProject} to ${project} user action taken`,
            project,
            taskId,
            created: new Date().toISOString(),
        })));
};

const notesWithoutProject = (notes, { project }) => {
    return notes.filter(note => note.project !== project);
};

const notesWithoutTask = (notes, { task: { id } }) => {
    return notes.filter(note => note.taskId !== id);
}

const _notesReducer = createReducer(
    initalState,
    on(undoAction, produce(replacedState)),
    on(redoAction, produce(replacedState)),
    on(addNote, produce(notesWithAddition)),
    on(removeNote, produce(notesWithRedaction)),
    on(createTask, produce(noteAboutTaskAction)),
    on(moveTask, produce(noteAboutTaskAction)),
    on(updateTask, produce(noteAboutTaskAction)),
    on(flagTask, produce(noteAboutTaskAction)),
    on(toggleTaskVisibility, produce(noteAboutTaskAction)),
    on(renameProject, produce(noteAboutProjectAction)),
    on(removeProject, produce(notesWithoutProject)),
    on(removeTask, produce(notesWithoutTask)),
);

export function notesReducer(state, action) {
    return _notesReducer(state, action);
}