import { Component, HostListener, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';

@Component({
  selector: 'sh-frame',
  templateUrl: './frame.component.html',
  styleUrls: ['./frame.component.scss'],
})
export class FrameComponent {
  public showMenu = true;
  public context;
  public routes$ = import("../app-routing.module").then(({ routes }) => {
    return routes
      .find(route => route.component === FrameComponent)
      .children
      .filter(route => !route.redirectTo)
      .map(route => ({ path: route.path, ... route.data }));
  });

  constructor(
    private store: Store<any>,
  ) {}

  // Globally disables the context menu so we can use our
  // internal dropdown menus to provide quick actions.
  @HostListener("window:contextmenu", ["$event"])
  public preventContextMenu(event: MouseEvent) {
    event.stopPropagation();
    event.preventDefault();
  }

  public activateRoute(event) {
    const { route } = event;
    this.context = route && route.snapshot && route.snapshot.data;
  }

  public goBack() {
    history.back();
  }

  public deactivateRoute(event) {
    this.context = undefined;
  }
}
