
    <div style="display: flex; flex-direction: column;" class="container">
        <div style="flex: 1; display: flex; flex-direction: row;" class="row">
            <span style="margin-top: 2px; font-size: 18px;">About {{ nameToTitle(pkgJSON.name) }}</span>
        </div>
        <div style="flex: 1; text-align: center; margin-top: 30px; display: flex; flex-direction: row;" class="row">
          <span>Version {{ pkgJSON.version }} (Offical build)</span>
        </div>
        <div style="flex: 1; display: flex; flex-direction: row; margin-top: 10px;" class="row">
          <span>Copyright 2020 Brandon Bleau. All rights reserved.</span>
        </div>
    </div>
  