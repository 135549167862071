import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { map, withLatestFrom } from 'rxjs/operators';
import { removeProject, renameProject, selectProject } from '../actions/project.actions';
import { projects, selectedProject } from '../selectors';
import { ProjectNS } from '../types/project.interface';
import { StoreNS } from '../types/store.interface';

@Injectable()
export class ProjectService {
    public selectedProject$ = this.store.pipe(select(selectedProject));
    public projects$ = this.store.pipe(select(projects));

    public _onRemoveProject$$ = createEffect(() => this.actions$.pipe(
        ofType(removeProject),
        withLatestFrom(this.projects$),
        map(([{ project }, projects]) => {
            const firstProject = projects
                .filter(_project => _project !== project)
                .pop();

            return selectProject({ project: firstProject });
        })
    ));

    constructor(
        private actions$: Actions,
        private store: Store<StoreNS.Object>,
    ) {}

    public rename(project: ProjectNS.Value, to: ProjectNS.Value) {
        this.store.dispatch(renameProject({ from: project, to }));
    }

    public remove(project: ProjectNS.Value) {
        this.store.dispatch(removeProject({ project }));
    }

    public select(project: ProjectNS.Value) {
        this.store.dispatch(selectProject({ project }));
    }
}